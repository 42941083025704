@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
}

html,
body {
  height: 100%;
  line-height: 1em;
  background: #02004b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:active,
button:focus {
  outline: none;
}

h1,
h2,
h3,
h4 {
  line-height: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label:hover {
  cursor: pointer;
}

#root {
  position: relative;
  min-height: 100%;
}
